import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'
import { usePageData, useTranslatableContent } from 'hooks'

export default function PageSeo () {
  const { data, seo, template } = usePageData();
  const { t, i18n } = useTranslation('common');
  const maybeTranslate = useTranslatableContent();

  const initialMeta = useMemo(() => ({
    description: t('site.description'),
    image: null,
    siteName: t('site.name'),
    title: t('site.title'),
    url: window.location.href,
  }), [t]);

  const [meta, setMeta] = useState(initialMeta);

  const [product, setProduct] = useState(null);

  // const content = useMemo(() => data?.content || null, [data]);

  useEffect(() => {
    if (seo || template) {
      const newMeta = {
        description: seo?.description ? maybeTranslate(seo.description) : t('site.description'),
        image: seo?.image || null,
        siteName: t('site.name'),
        title: seo?.title ? `${maybeTranslate(seo.title)} — ${t('site.title')}` : t('site.title'),
        url: window.location.href,
      };

      setMeta(prevMeta => (JSON.stringify(prevMeta) !== JSON.stringify(newMeta) ? newMeta : prevMeta));

      if (template === 'camper-page' && data?.content) {
        const camper = data.content;
        const images = camper.media?.map(image => image.url) || [];
        if (camper.featured_image) images.unshift(camper.featured_image.url);

        setProduct({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: maybeTranslate(camper.name),
          image: images,
          offers: {
            '@type': 'Offer',
            priceCurrency: 'EUR',
            price: camper.price,
            availability: 'https://schema.org/InStock',
            url: newMeta.url,
          },
        });
        newMeta.title = `${maybeTranslate(camper.name)} — ${t('site.title')}`;
      } else if (template === 'accessory-page' && data?.content) {
        const accessory = data.content.find(block => block.layout === 'details')?.attributes || null;
        if (accessory) {
          newMeta.title = `${maybeTranslate(accessory.name)} — ${t('site.title')}`;
          newMeta.description = maybeTranslate(accessory.description) ?? newMeta.description;

          setProduct({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: maybeTranslate(accessory.name),
            description: maybeTranslate(accessory.description),
            image: accessory.media?.map(image => image.url) || [],
            offers: {
              '@type': 'Offer',
              priceCurrency: 'EUR',
              price: accessory.price,
              availability: accessory.stock_quantity > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
              url: newMeta.url,
            },
          });
        }
      }
      setMeta(newMeta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, seo, template]);

  return <Helmet>
    <html lang={i18n.language} />

    {/* Open Graph meta tags*/}
    <meta property="og:title" content={meta.title} />
    <meta property="og:description" content={meta.description} />
    <meta property="og:url" content={meta.url} />
    {meta?.image && <meta property="og:image" content={meta.image} />}
    <meta property="og:site_name" content={meta.siteName} />

    {/* Standard meta tags */}
    <title>{meta.title}</title>
    <meta name="description" content={meta.description} />
    <link rel="canonical" href={meta.url} />

    {/* Product meta tags */}
    {product && <script type="application/ld+json">
      {JSON.stringify(product)}
    </script>}
  </Helmet>
}
